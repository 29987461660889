document.addEventListener('DOMContentLoaded', (event) => {
    new fullpage('#fullpage', {
        navigation: false,
        navigationPosition: 'right',
        css3: true,
        scrollingSpeed: 700,
        slidesNavigation: true,
        slidesNavPosition: 'top',
        autoScrolling: true,
        fitToSection: true,
        fitToSectionDelay: 600,
        scrollBar: true,
        easing: 'easeInOutCubic',
        continuousVertical: true,
        continuousHorizontal: true,
        scrollHorizontally: true,
    });
});
